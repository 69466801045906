import React from 'react';
import cx from 'classnames';

import ModuleWrapper from '../moduleWrapper';
import SectorImageText from './sectorImageText';

const SectorPortal = ({ config, title, desc, sectors }) => {
  const [activeTab, setActiveTab] = React.useState(sectors[0]?.sector?.title);

  function handleChange(e) {
    document
      .getElementById(e.target.value.toLowerCase().replace(' ', '-'))
      .scrollIntoView({ behavior: 'smooth' });
  }

  return (
    <ModuleWrapper {...config}>
      <div className='px-gutter pt-10 md:pt-0'>
        <div className='text-center'>
          <h1 className='blockH1'>{title}</h1>
          <p className='mt-8 max-w-[700px] w-full mx-auto text-base md:text-lg'>
            {desc}
          </p>
        </div>

        {/* Desktop Tabs */}
        <div className='hidden md:flex flex-wrap md:w-[100%] lg:w-[80%] xl:w-[75%] md:mx-auto mt-20 items-center justify-center '>
          {sectors.map((item, i) => {
            const { title } = item.sector || {};
            return (
              <a
                key={i}
                href={`#${title
                  .toLowerCase()
                  .replace(/ /g, '-')
                  .replace(/[&/]/g, 'b')}`}
                // offset={-100}
                // smooth={true}
                onClick={() => setActiveTab(title)}
                className={cx('text-base cursor-pointer px-8 h-12', {
                  'font-bold underline': activeTab === title,
                })}
              >
                {title}
              </a>
            );
          })}
        </div>

        {/* Mobile Tabs */}
        <div className='mt-12 md:hidden px-gutter'>
          <select
            onChange={(e) => handleChange(e)}
            className='w-full font-bold border-t-0 border-x-0 px-0 border-b border-black focus:ring-0 focus:outline-none focus:border-black'
          >
            {sectors.map((item, i) => (
              <option value={item.sector?.title}>{item.sector?.title}</option>
            ))}
          </select>
        </div>
      </div>

      {/* Image Texts */}
      <div className='mt-24 md:mt-56 flex flex-col space-y-24 md:space-y-64 '>
        {sectors.map((row, i) => (
          <SectorImageText key={i} {...row} i={i} />
        ))}
      </div>
    </ModuleWrapper>
  );
};

export default SectorPortal;
