import React from 'react';
import { graphql } from 'gatsby';
import { PageMeta } from '@components/pageMeta';
import ModuleZone from '~moduleZone';
import Layout from '@components/layout';

const PageTemplate = ({ data: { page } }) => {
  const { pageModules, seo, pageSettings } = page || {};
  const { pageColour, reverseHeader, unpinnedHeader } = pageSettings || {};

  return (
    <Layout
      unpinnedHeader={unpinnedHeader}
      reverseHeader={reverseHeader}
      pageColour={pageColour}
    >
      <PageMeta {...seo} />
      {pageModules && <ModuleZone {...pageModules} pageColour={pageColour} />}
    </Layout>
  );
};

export default PageTemplate;

export const pageQuery = graphql`
  query standardPageQuery($slug: String!) {
    page: sanityModularPage(slug: { current: { eq: $slug } }) {
      seo {
        ...SEO
      }
      pageModules: modules {
        ...PageModules
      }
      pageSettings {
        reverseHeader
        unpinnedHeader
        pageColour {
          hex
        }
      }
    }
  }
`;
