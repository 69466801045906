import React from 'react';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';
import SectorPortal from './modules/sectorPortal';

const ImageText = loadable(() => import('./modules/imageText'));
const BannerTouchpoint = loadable(() => import('./modules/bannerTouchpoint'));
const SquareTouchpoint = loadable(() => import('./modules/squareTouchpoint'));
const SectorSlider = loadable(() => import('./modules/sectorSlider'));
const ImageSlider = loadable(() => import('./modules/imageSlider'));
// const SectorPortal = loadable(() => import("./modules/sectorPortal"));

const TeamSlider = loadable(() => import('./modules/teamSlider'));
const ContactPortal = loadable(() => import('./modules/contactPortal'));
const EventPortal = loadable(() => import('./modules/eventPortal'));
const SingleColText = loadable(() => import('./modules/singleColText'));
const AccordionSet = loadable(() => import('./modules/accordionSet'));
const Resources = loadable(() => import('./modules/resources'));
const VideoBlock = loadable(() => import('./modules/videoBlock'));
const PodcastBlock = loadable(() => import('./modules/podcastBlock'));
const WebinarBlock = loadable(() => import('./modules/webinarBlock'));
const MediaBanner = loadable(() => import('./modules/mediaBanner'));
const CardCarousel = loadable(() => import('./modules/cardCarousel'));

const ModuleZone = ({ modules, pageColour }) => {
  const component = modules.map((item, index) => {
    switch (item._type) {
      case 'imageText':
        return <ImageText key={`image-text-${index}`} {...item} />;
      case 'bannerTouchpoint':
        return (
          <BannerTouchpoint key={`banner-touchpoint-${index}`} {...item} />
        );
      case 'squareTouchpoint':
        return (
          <SquareTouchpoint key={`square-touchpoint-${index}`} {...item} />
        );
      case 'sectorSlider':
        return <SectorSlider key={`sector-slider-${index}`} {...item} />;
      case 'imageSlider':
        return <ImageSlider key={`image-slider-${index}`} {...item} />;
      case 'sectorPortal':
        return <SectorPortal key={`sector-portal-${index}`} {...item} />;
      case 'teamSlider':
        return <TeamSlider key={`team-slider-${index}`} {...item} />;
      case 'contactPortal':
        return <ContactPortal key={`contact-portal-${index}`} {...item} />;
      case 'eventPortal':
        return (
          <EventPortal
            key={`event-portal-${index}`}
            {...item}
            pageColour={pageColour}
          />
        );
      case 'singleColText':
        return <SingleColText key={`single-col-text-${index}`} {...item} />;
      case 'accordionSet':
        return <AccordionSet key={`accordion-set-${index}`} {...item} />;
      case 'resources':
        return <Resources key={`resources-${index}`} {...item} />;
      case 'videoBlock':
        return <VideoBlock key={`video-block-${index}`} {...item} />;
      case 'podcastBlock':
        return <PodcastBlock key={`podcast-block-${index}`} {...item} />;
      case 'webinarBlock':
        return <WebinarBlock key={`webinar-block-${index}`} {...item} />;
      case 'mediaBanner':
        return <MediaBanner key={`media-banner-${index}`} {...item} />;
      case 'cardCarousel':
        return <CardCarousel key={`card-carousel-${index}`} {...item} />;
      default:
        return null;
    }
  });
  return <>{component}</>;
};

ModuleZone.propTypes = {
  modules: PropTypes.array.isRequired,
};

export default ModuleZone;
