import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import cx from 'classnames';
import SimplePortableText from '@components/sanity/simplePortableText';
import { SanityImage } from '@components/sanity/sanityImage';
import { RiDownloadLine } from 'react-icons/ri';

const renderResources = (resources) => {
  if (!resources.length) return null;
  return resources.map((item, i) => <Resource key={i} {...item} />);
};

const Resource = ({ label, file, url }) => {
  //convert bytes to KB/MB/GB
  const fileSize = (bytes) => {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return '0 Byte';
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
  };

  const hoverStyles = `
          .resource:hover{
              background: #16A9D4;
              color: #fff;
          }
      `;
  return (
    <>
      <style>{hoverStyles}</style>
      <a
        href={file ? file?.asset?.url : url}
        target='_blank'
        rel='noreferrer'
        className='resource transition-all ease-in-out duration-300 flex  md:flex-row gap-x-12 gap-y-2 items-center justify-between border border-[#00000020] rounded-md py-3 px-6'
      >
        <span className='text-base font-bold w-full md:w-max text-left md:text-center'>
          {label}
        </span>
        <div className='flex gap-x-6 text-base items-center w-full justify-end md:w-max'>
          {file && <span className='uppercase'>{file?.asset?.extension}</span>}
          {file && <span>{fileSize(file?.asset?.size)}</span>}
          <RiDownloadLine />
        </div>
      </a>
    </>
  );
};

const SectorImageText = ({ iconPlacement, sector, i }) => {
  const { desc, featuredImage, icon, title, NzResources, IsraelResources } =
    sector || {};

  // const { ref, inView } = useInView({
  //   threshold: 0.1,
  //   triggerOnce: true,
  // });

  const iconRef = useRef();

  useEffect(() => {
    var y;

    if (window?.innerWidth > 768) {
      y = -100;
    } else {
      y = 50;
    }

    gsap.to(iconRef.current, {
      yPercent: y,
      ease: 'none',
      scrollTrigger: {
        trigger: iconRef.current,
        start: 'top bottom', // the default values
        end: 'bottom top',
        scrub: true,
      },
    });
  }, []);

  return (
    <div
      // ref={ref}
      id={title.toLowerCase().replace(/ /g, '-').replace(/[&/]/g, 'b')}
      className={cx('grid grid-cols-14 pt-5', {
        // "not-in-view": !inView,
        // "in-view": inView,
      })}
    >
      {/* Text & Icon */}
      <div
        className={cx('relative flex flex-col gap-y-6 md:row-start-1', {
          'col-start-2 md:col-start-9 col-end-14 md:ml-12': i % 2 === 0,
          'col-end-14 col-start-2 md:col-end-7 md:mr-12': i % 2 === 1,
        })}
      >
        <div
          className={cx('h-full flex', {
            'items-start': iconPlacement !== 'top',
            'items-end': iconPlacement === 'top',
          })}
        >
          <div className='relative z-10'>
            <h3 className='blockH3'>{title}</h3>
            <SimplePortableText text={desc} />
          </div>
        </div>

        <div ref={iconRef} className='relative w-full h-full'>
          {icon && (
            <SanityImage
              image={icon}
              className={cx('absolute md:h-80 w-auto', {
                'right-0 md:right-[unset] top-6 md:top-0 h-36':
                  iconPlacement === 'top',
                '-top-12 md:top-[unset] md:bottom-0 right-0 h-56':
                  iconPlacement === 'bottom',
                'left-1/3 -top-10 md:top-[unset] md:-bottom-1/4 md:-left-1/3 h-40':
                  iconPlacement === 'centerBottom' && i % 2 === 0,
                'left-1/3 -top-10 md:top-[unset] md:-bottom-1/4 md:-right-1/3 h-40':
                  iconPlacement === 'centerBottom' && i % 2 === 1,
              })}
            />
          )}
        </div>
      </div>

      {/* Image */}
      <div
        className={cx('relative z-10 md:row-start-1 mt-7 md:mt-0', {
          'col-end-14 col-start-2 md:col-end-9 md:mr-12': i % 2 === 0,
          'col-start-2 md:col-start-7 col-end-14 md:ml-12': i % 2 === 1,
        })}
      >
        {featuredImage && <SanityImage image={featuredImage} />}
      </div>
      <div className='col-start-2 col-end-14 lg:col-start-4 lg:col-end-12 mt-8 mb-4 flex flex-col gap-y-3'>
        <h1 className='blockH5 pb-4'>Resources</h1>
        {renderResources(NzResources)}
        {renderResources(IsraelResources)}
      </div>
    </div>
  );
};

export default SectorImageText;
